import to from 'await-to-js'
import { wait } from '@/helpers/time'

export async function toRetry(action, providedOptions) {
  if (typeof action !== 'function') throw new Error('[toRetry] - "action" must be a function')
  return await to(retry(action, providedOptions))
}

export async function retry(action, providedOptions = {}) {
  const options = {
    retryCount: 3,
    retryDelay: 1000,
    isSuccessfulInResponse: () => true,
    ...providedOptions,
  }
  const [error, response] = await to(action())
  const { retryDelay, isSuccessfulInResponse } = options

  if (options.retryCount > 0 && (error || !isSuccessfulInResponse(response))) {
    options.retryCount--
    await wait(retryDelay)
    return await retry(action, options)
  }
  if (error) throw error
  return response
}

/**
 * The spinner should be displayed for at least `minRequestTime`. Prevent flashing the
 * spinner when the request is almost instant.
 *
 * @name loadForAtLeast
 * @function
 * @param {promise} promise
 * @param {number} minRequestTime
 *
 * @returns {promise}
 */
export function loadForAtLeast(promise, minRequestTime = 400) {
  const timeBeforeRequest = new Date().getTime()

  return promise.then(data => {
    const timeAfterRequest = new Date().getTime()
    const requestExecutionTime = timeAfterRequest - timeBeforeRequest
    const loadingStateRemainingTime = minRequestTime - requestExecutionTime

    return loadingStateRemainingTime > 0
      ? new Promise(resolve => setTimeout(() => resolve(data), loadingStateRemainingTime))
      : data
  })
}

export async function toData(promise, options) {
  return toDataHandler(await to(promise), options)
}

export function toDataHandler([error, data], options) {
  const { isReporting = false } = options || {}

  if (error) {
    if (typeof window === 'undefined') {
      console.error(error) // eslint-disable-line no-console
    } else {
      self.$nuxt.$store.dispatch('flash/addConnectionErrorMessage')

      if (isReporting) {
        self.$nuxt.$sentry.captureException(error)
      }
    }
  }
  return data
}

export async function toDataForAtLeast(promise) {
  return await loadForAtLeast(toData(promise))
}

export function getGqlErrorMessages(errorResponse) {
  const errors = errorResponse?.graphQLErrors || errorResponse?.errors || []
  const errorMessages = []
  errors.forEach(error => {
    const exceptions = error?.extensions?.exception?.data?.data || []
    if (!Array.isArray(exceptions)) return

    exceptions.forEach(exception => {
      const messages = exception.messages || []
      messages.forEach(message => errorMessages.push(message))
    })
  })
  return errorMessages.length ? errorMessages : ['errors.connection']
}
