import { ADMIN_STATUSES } from 'acces-impot-settings-report/constants/admin'
import ADMIN_ASSIGNED_REPORT_LIST_GQL from '@/api/queries/admin-assigned-report-list.gql'
import ADMIN_ASSIGNED_REPORT_GQL from '@/api/queries/admin-assigned-report.gql'
import { EventBus, EVENTS } from '@/services/event-bus'
import { FILTER_CATEGORIES, MAIN_FILTER_WHERE_ARGS } from './constants'

export function getCachedResult(client, queryParams) {
  let cachedResult
  try {
    cachedResult = client.readQuery(queryParams)
  } catch (e) {}

  return cachedResult
}

export function addToAssignedReportListCache(client, reports) {
  const reportCategories = { completed: [], notCompleted: [] }

  reports.forEach(report => {
    const prop =
      report.adminStatus === ADMIN_STATUSES.completed
        ? FILTER_CATEGORIES.completed
        : FILTER_CATEGORIES.notCompleted
    reportCategories[prop].push(report)
  })

  Object.values(FILTER_CATEGORIES).forEach(filterCategory => {
    const reportListQueryParams = {
      query: ADMIN_ASSIGNED_REPORT_LIST_GQL,
      variables: { where: MAIN_FILTER_WHERE_ARGS[filterCategory] },
    }
    let cachedReportList = getCachedResult(client, reportListQueryParams)

    if (!cachedReportList) {
      cachedReportList = {
        me: { assignedReportList: { reports: [] }, __typename: 'UsersPermissionsMe' },
      }
    }
    const assignedReportList = [...cachedReportList.me.assignedReportList.reports]
    assignedReportList.unshift(...reportCategories[filterCategory])
    assignedReportList.sort(
      (a, b) => new Date(a.statusUpdatedAt).getTime() - new Date(b.statusUpdatedAt).getTime()
    )

    const updatedData = {
      ...cachedReportList,
      me: {
        ...cachedReportList.me,
        assignedReportList,
      },
    }
    client.writeQuery({ ...reportListQueryParams, data: updatedData })
  })
  EventBus.$emit(EVENTS.adminReportListCacheUpdate)
}

export function updateProtectedReportPostDocumentsCache({
  client,
  reportId,
  language,
  protectedReportPostDocuments,
}) {
  const queryParams = {
    query: ADMIN_ASSIGNED_REPORT_GQL,
    variables: { id: reportId, language },
  }
  const cachedReport = getCachedResult(client, queryParams)
  if (!cachedReport?.me?.assignedReport) return

  const updatedData = {
    ...cachedReport,
    me: {
      ...cachedReport.me,
      assignedReport: {
        ...cachedReport.me.assignedReport,
        protectedReportPostDocuments,
      },
    },
  }
  client.writeQuery({ ...queryParams, data: updatedData })
  EventBus.$emit(EVENTS.adminReportCacheUpdate)
}
