export const ROUTES_NAME_SEPARATOR = '___'

const DOCUMENT_TO_APPROVE_FILENAME = 'Approve'
export const DOCUMENT_TO_APPROVE_PATH_NAME = DOCUMENT_TO_APPROVE_FILENAME.toLowerCase()

export const ROUTE_NAMES = {
  home: 'Index',
  contact: 'Contact',
  faq: {
    index: 'Faq-Index',
    security: 'Faq-Security',
    general: 'Faq-General',
  },
  account: {
    index: 'Account-Index',
  },
  admin: {
    index: 'Admin-Reports-Filter', // Admin-Index redirects to Admin-Reports-Filter
    reports: 'Admin-Reports-Filter',
    report: {
      index: 'Admin-Report-Index',
      emails: 'Admin-Report-Emails',
      processing: 'Admin-Report-Processing',
      order: 'Admin-Report-Order',
      details: 'Admin-Report-Details',
    },
    training: {
      index: 'Admin-Training-Index',
      examId: 'Admin-Training-Exam-Index',
      examResultId: 'Admin-Training-Exam-Result',
      category: 'Admin-Training-Category-Category',
    },
    remuneration: {
      index: 'Admin-Remuneration-Index',
    },
  },
  reports: {
    index: 'Account-Index',
    year: 'Account-Reports-Year-Index',
    documentToApprove: `Account-Reports-Year-${DOCUMENT_TO_APPROVE_FILENAME}`,
  },
  accountNotConfirmed: 'AccountNotConfirmed',
  auth: 'Auth',
  maintenance: 'Maintenance',
}

export const AUTH_TYPES = {
  login: 'login',
  signup: 'signup',
  passwordForgotten: 'password-forgotten',
  resetPassword: 'reset-password',
}

export const AUTH_REFERER_KEY = 'auth-referer'
export const SEARCH_URL_PARAM = 'q'
export const ADMIN_TRAINING_CATEGORY_URL_PARAM = 'category'
