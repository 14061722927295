import { MESSAGE_TYPES } from 'acces-impot-utils/lib/validation'
import { getApiClient, FETCH_POLICY } from '@/api/client'
import { EventBus, EVENTS } from '@/services/event-bus'
import { loadForAtLeast, toRetry, toData } from '@/helpers/request'
import ADMIN_ASSIGNED_REPORT_LIST_GQL from '@/api/queries/admin-assigned-report-list.gql'
import ADMIN_ASSIGNED_REPORT_GQL from '@/api/queries/admin-assigned-report.gql'
import ADMIN_ASSIGNED_REPORT_POST_DOCUMENTS_GQL from '@/api/queries/admin-assigned-report-post-documents.gql'
import ADMIN_ASSIGN_NEXT_AVAILABLE_REPORT_GQL from '@/api/mutations/admin-assign-next-available-report.gql'
import ADMIN_UPDATE_ADMIN_STATUS_GQL from '@/api/mutations/admin-update-admin-status.gql'
import ADMIN_ASSIGNED_REPORT_LIST_FRAGMENT_GQL from '@/api/fragments/assignedReportForList.gql'
import ADMIN_GENERATE_ALL_FILES_FOR_REPORT_GQL from '@/api/mutations/admin-generate-all-files-for-report.gql'
import ADMIN_UPLOAD_POST_DOCUMENTS_GQL from '@/api/mutations/admin-upload-post-documents.gql'
import ADMIN_DELETE_POST_DOCUMENT_GQL from '@/api/mutations/admin-delete-post-document.gql'
import ADMIN_UPDATE_DELIVERY_CONFIRMATION_NUMBERS from '@/api/mutations/admin-update-delivery-confirmation-numbers.gql'
import ADMIN_SEND_HELP_MESSAGE from '@/api/mutations/admin-send-help-message.gql'
import ADMIN_ADD_NOTE from '@/api/mutations/admin-add-note.gql'
import ADMIN_SEND_EMAIL_TEMPLATE from '@/api/mutations/admin-send-email-template.gql'
import ADMIN_FLAG_AS_REPORT_WITH_ISSUES from '@/api/mutations/admin-flag-as-report-with-issues.gql'
import ADMIN_SET_WAITING_FOR_REVIEW from '@/api/mutations/admin-set-waiting-for-review.gql'
import { DEFAULT_ASSIGNED_REPORT_LIST_WHERE, MESSAGE_TYPE_MAP } from './constants'
import {
  getCachedResult,
  addToAssignedReportListCache,
  updateProtectedReportPostDocumentsCache,
} from './utils'

const FETCH_DELAY = 20 * 1000
const lastFetched = {}

const ALL_POST_DOCUMENT_ID = 'all'

let lastCheckForNextAvailableReportAt = new Date().getTime()
const NEXT_AVAILABLE_REPORT_DEBOUNCE_TIME = 1000

const AdminService = {
  async fetchAssignedReports({ appOrVm, where = DEFAULT_ASSIGNED_REPORT_LIST_WHERE }) {
    return await this.fetch({
      client: getApiClient(appOrVm),
      query: ADMIN_ASSIGNED_REPORT_LIST_GQL,
      queryId: 'ADMIN_ASSIGNED_REPORT_LIST',
      variables: { where },
      getResult: data => data?.me?.assignedReportList,
    })
  },

  async fetchAssignedReport({ appOrVm, id, language }) {
    return await this.fetch({
      client: getApiClient(appOrVm),
      query: ADMIN_ASSIGNED_REPORT_GQL,
      queryId: 'ADMIN_ASSIGNED_REPORT',
      variables: { id, language },
      getResult: data => data?.me?.assignedReport,

      getPartialCachedResult: client => {
        const partialReport = client.readFragment({
          id: client.cache.identify({ __typename: 'Report', id }),
          fragment: ADMIN_ASSIGNED_REPORT_LIST_FRAGMENT_GQL,
        })
        return { me: { assignedReport: partialReport } }
      },
    })
  },

  async refreshAssignedReportPostDocuments({ appOrVm, id, language }) {
    const result = await this.fetch({
      client: getApiClient(appOrVm),
      query: ADMIN_ASSIGNED_REPORT_POST_DOCUMENTS_GQL,
      queryId: 'ADMIN_ASSIGNED_REPORT_POST_DOCUMENTS',
      variables: { id: String(id), language },
      getResult: data => data?.me?.assignedReport,
      fetchDelay: 0,
    })
    EventBus.$emit(EVENTS.adminReportCacheUpdate)

    const { protectedReportPostDocuments } = result || {}

    if (protectedReportPostDocuments) {
      updateProtectedReportPostDocumentsCache({
        client,
        reportId,
        language,
        protectedReportPostDocuments,
      })
    }
    return result
  },

  async fetch({
    client,
    query,
    queryId = '',
    variables,
    getResult,
    getPartialCachedResult,
    fetchDelay = FETCH_DELAY,
  }) {
    const cacheKey = `${queryId}_${JSON.stringify(variables || {})}`
    const timeNow = new Date().getTime()

    const queryParams = { query, variables }
    let cachedResult = getCachedResult(client, queryParams)
    let hasCachedData = !!cachedResult
    let isFetchingServer = false

    if (hasCachedData && lastFetched[cacheKey] && lastFetched[cacheKey] + fetchDelay >= timeNow) {
      const data = getResult(cachedResult)
      const lastFetchedAt = lastFetched[cacheKey]
      return { data, asyncData: Promise.resolve({ data, lastFetchedAt }), hasCachedData }
    }
    const previousLastFetched = lastFetched[cacheKey]
    lastFetched[cacheKey] = timeNow

    let resolveAsyncData
    const asyncData = new Promise(resolve => (resolveAsyncData = resolve))
    if (!cachedResult) cachedResult = getPartialCachedResult?.(client)

    const resultPromise = toRetry(() =>
      client.query({
        ...queryParams,
        fetchPolicy: FETCH_POLICY.networkOnly,
      })
    ).then(([, response]) => {
      const result = getResult(response?.data)
      lastFetched[cacheKey] = result ? new Date().getTime() : previousLastFetched

      const data = result
      resolveAsyncData({ data, lastFetchedAt: lastFetched[cacheKey] })
      return data
    })

    return {
      data: getResult(cachedResult) || (await resultPromise),
      asyncData,
      queryParams,
      hasCachedData,
      isFetchingServer,
    }
  },

  async assignNextAvailableReport({ appOrVm, language }) {
    const client = getApiClient(appOrVm)
    const store = appOrVm.$store || appOrVm.store
    const now = new Date().getTime()

    if (now - lastCheckForNextAvailableReportAt < NEXT_AVAILABLE_REPORT_DEBOUNCE_TIME) return

    lastCheckForNextAvailableReportAt = now

    const result = await loadForAtLeast(
      toData(
        client.mutate({
          mutation: ADMIN_ASSIGN_NEXT_AVAILABLE_REPORT_GQL,
          variables: { language },
        }),
        { isReporting: true }
      )
    )
    if (result?.data?.assignNextAvailableReport?.reports) {
      const { reports } = result.data.assignNextAvailableReport
      addToAssignedReportListCache(client, reports)
    }
    if (result?.data?.assignNextAvailableReport?.message) {
      const { message } = result.data.assignNextAvailableReport

      store?.dispatch('flash/addMessage', {
        type: MESSAGE_TYPE_MAP[message.type],
        text: message.text,
        autoHideAfter: 4000,
      })
    }
  },

  async updateAdminStatus({ appOrVm, id, adminStatus, language, note, loadForAtLeastTime }) {
    const client = getApiClient(appOrVm)
    const store = appOrVm.$store || appOrVm.store

    const result = await loadForAtLeast(
      toData(
        client.mutate({
          mutation: ADMIN_UPDATE_ADMIN_STATUS_GQL,
          variables: { id, adminStatus, language, note },
        }),
        { isReporting: true }
      ),
      loadForAtLeastTime
    )
    if (result?.data?.updateAdminStatus?.report) {
      EventBus.$emit(EVENTS.adminReportCacheUpdate)
    }
    if (result?.data?.updateAdminStatus?.message) {
      const { message } = result.data.updateAdminStatus

      store?.dispatch('flash/addMessage', {
        type: MESSAGE_TYPE_MAP[message.type],
        text: message.text,
        autoHideAfter: 4000,
      })
    }
  },

  async uploadPostDocuments({ appOrVm, reportId, documentTypeId, personKey, file, language }) {
    const client = getApiClient(appOrVm)
    const store = appOrVm.$store || appOrVm.store

    const result = await toData(
      client.mutate({
        mutation: ADMIN_UPLOAD_POST_DOCUMENTS_GQL,
        variables: {
          input: {
            where: { reportId },
            data: { documentTypeId, personKey, file },
          },
          language,
        },
      }),
      { isReporting: true }
    )
    const { protectedReportPostDocuments, message } =
      result?.data?.uploadFileInReportPostDocument || {}

    if (protectedReportPostDocuments) {
      updateProtectedReportPostDocumentsCache({
        client,
        reportId,
        language,
        protectedReportPostDocuments,
      })
    }
    if (message) {
      store?.dispatch('flash/addMessage', {
        type: MESSAGE_TYPE_MAP[message.type],
        text: message.text,
        autoHideAfter: 4000,
      })
    }
    return { protectedReportPostDocuments }
  },

  async generateAllFilesForReport({ appOrVm, reportId, language }) {
    const client = getApiClient(appOrVm)
    const store = appOrVm.$store || appOrVm.store

    const result = await toData(
      client.mutate({
        mutation: ADMIN_GENERATE_ALL_FILES_FOR_REPORT_GQL,
        variables: { reportId: Number(reportId), language },
      }),
      { isReporting: true }
    )
    const { report, message } = result?.data?.generateAllFilesForReport || {}
    const errors = result?.errors || []

    if (report) {
      client.cache.modify({
        id: client.cache.identify({ __typename: 'Report', id: report.id }),
        fields: {
          pdfJobStartedAt: () => new Date().toISOString(),
          pdfJobFailedAt: () => null,
        },
      })
    }
    EventBus.$emit(EVENTS.adminReportCacheUpdate)

    if (message) {
      store?.dispatch('flash/addMessage', {
        type: MESSAGE_TYPE_MAP[message.type],
        text: message.text,
        autoHideAfter: 4000,
      })
      if (message.type === MESSAGE_TYPES.error) {
        errors.push(message.text)
      }
    }

    if (!errors.length) {
      updateProtectedReportPostDocumentsCache({
        client,
        reportId,
        language,
        protectedReportPostDocuments: [],
      })
    }
    return { errors }
  },

  async deleteAllPostDocuments({ appOrVm, reportId, language }) {
    await this.deletePostDocument({
      appOrVm,
      reportId,
      postDocumentId: ALL_POST_DOCUMENT_ID,
      language,
    })
  },

  async deletePostDocument({ appOrVm, reportId, postDocumentId, language }) {
    const client = getApiClient(appOrVm)
    const store = appOrVm.$store || appOrVm.store

    const result = await toData(
      client.mutate({
        mutation: ADMIN_DELETE_POST_DOCUMENT_GQL,
        variables: {
          input: { where: { reportId, postDocumentId }, data: { language } },
          language,
        },
      }),
      { isReporting: true }
    )
    const { protectedReportPostDocuments, message } =
      result?.data?.deleteReportPostDocumentAndFile || {}

    if (protectedReportPostDocuments) {
      updateProtectedReportPostDocumentsCache({
        client,
        reportId,
        language,
        protectedReportPostDocuments,
      })
    }
    if (message) {
      store?.dispatch('flash/addMessage', {
        type: MESSAGE_TYPE_MAP[message.type],
        text: message.text,
        autoHideAfter: 4000,
      })
    }
  },

  async updateDeliveryConfirmationNumbers({
    appOrVm,
    id,
    personKey,
    provincial,
    federal,
    language,
    loadForAtLeastTime,
  }) {
    const client = getApiClient(appOrVm)
    const store = appOrVm.$store || appOrVm.store

    const result = await loadForAtLeast(
      toData(
        client.mutate({
          mutation: ADMIN_UPDATE_DELIVERY_CONFIRMATION_NUMBERS,
          variables: { id, personKey, provincial, federal, language },
        }),
        { isReporting: true }
      ),
      loadForAtLeastTime
    )
    const { reportPerson, message } = result?.data?.updateDeliveryConfirmationNumbers || {}

    if (reportPerson) EventBus.$emit(EVENTS.adminReportCacheUpdate)

    if (message) {
      store?.dispatch('flash/addMessage', {
        type: MESSAGE_TYPE_MAP[message.type],
        text: message.text,
        autoHideAfter: 4000,
      })
    }
  },

  async writeMessage({ appOrVm, reportId, message, successMessage, mutationName }) {
    const client = getApiClient(appOrVm)
    const store = appOrVm.$store || appOrVm.store
    const input = { where: { reportId: String(reportId) }, data: { message } }

    const result = await toData(
      client.mutate({
        mutation:
          mutationName === 'sendAdminHelpMessage' ? ADMIN_SEND_HELP_MESSAGE : ADMIN_ADD_NOTE,
        variables: { input },
      }),
      { isReporting: true }
    )
    const { report, success } = result?.data?.[mutationName] || {}

    if (report) {
      EventBus.$emit(EVENTS.adminReportCacheUpdate)
    }
    // TODO: handle error -> show flash message
    if (success) {
      store?.dispatch('flash/addSuccessMessage', {
        key: mutationName,
        text: successMessage,
      })
    }
  },

  async sendHelpMessage({ appOrVm, reportId, message, successMessage }) {
    const mutationName = 'sendAdminHelpMessage'
    this.writeMessage({ appOrVm, reportId, message, successMessage, mutationName })
  },

  async addNote({ appOrVm, reportId, message, successMessage }) {
    const mutationName = 'addAdminNote'
    this.writeMessage({ appOrVm, reportId, message, successMessage, mutationName })
  },

  async flagAsReportWithDeliveryProblem({
    appOrVm,
    reportId,
    hasDeliveryProblem,
    messageType,
    successMessage,
  }) {
    const client = getApiClient(appOrVm)
    const store = appOrVm.$store || appOrVm.store
    const input = { where: { reportId: String(reportId) }, data: { hasDeliveryProblem } }

    const result = await loadForAtLeast(
      toData(
        client.mutate({
          mutation: ADMIN_FLAG_AS_REPORT_WITH_ISSUES,
          variables: { input },
        }),
        { isReporting: true }
      )
    )
    const { report } = result?.data?.flagAsReportWithDeliveryProblem || {}
    if (report) {
      EventBus.$emit(EVENTS.adminReportCacheUpdate)

      if (!!report.hasDeliveryProblem === !!hasDeliveryProblem && successMessage) {
        const fn = messageType === 'success' ? 'addSuccessMessage' : 'addWarnMessage'
        store?.dispatch(`flash/${fn}`, successMessage)
      }
    }
  },

  async setWaitingForReview({ appOrVm, reportId, isWaiting, language }) {
    const client = getApiClient(appOrVm)
    const store = appOrVm.$store || appOrVm.store

    const result = await loadForAtLeast(
      toData(
        client.mutate({
          mutation: ADMIN_SET_WAITING_FOR_REVIEW,
          variables: { id: Number(reportId), isWaiting, language },
        }),
        { isReporting: true }
      )
    )
    const { report } = result?.data?.setWaitingForReview || {}
    if (report) {
      EventBus.$emit(EVENTS.adminReportCacheUpdate)

      if (report.isWaitingForReview) {
        store?.dispatch(
          `flash/addSuccessMessage`,
          'Votre dossier est maintenant en attente de révision.'
        )
      }
    }
  },

  async sendEmailTemplate({ appOrVm, reportId, emailTemplate, missingInfos, successMessage }) {
    const client = getApiClient(appOrVm)
    const store = appOrVm.$store || appOrVm.store
    const input = { where: { reportId: String(reportId) }, data: { emailTemplate, missingInfos } }

    const result = await toData(
      client.mutate({
        mutation: ADMIN_SEND_EMAIL_TEMPLATE,
        variables: { input },
      }),
      { isReporting: true }
    )
    const { report, success } = result?.data?.sendAdminEmailTemplate || {}
    if (report) EventBus.$emit(EVENTS.adminReportCacheUpdate)

    // TODO: handle error -> show flash message
    if (success) store?.dispatch('flash/addSuccessMessage', successMessage)
  },
}

export default AdminService
